import React, { Component } from 'react';

import { isMobile } from "react-device-detect";

export default class Team extends Component {
  render() {
    return (
    	<section  id="team">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12 text-center">
			      	<h1 className={(isMobile ? "display-4" : "display-1") + " section-heading linea-arriba"}>
			            Equipo
			          </h1>
			      </div>
			    </div>
			    <div className="row">
			      <div className="col-md-5">
			        <img src="/equipo/rayen.png" width="100%" className="img-fluid" />
			      </div>
			      <div className="col-md-7">
		          <h4 className={(isMobile ? "text-center" : "") + " text-secondary service-heading pl-3"} >
		            Rayén Gutierrez Th.
		          </h4>
			        <p className="text-primary text-justify p-3">
			          Abogada de la Universidad Adolfo Ibáñez, egresada el
			          año 2002. Sus estudios escolares los realizó principalmente
			          en La Girouette y en la segundaria école Emile-Legault
			          en Montréal, Canadá. Se ha especializado en derecho
			          civil, laboral empresarial y comercial. Manejo de tres
			          idiomas, español, francés avanzado e inglés intermedio. Ha
			          desarrollado sus habilidades blandas naturales lo que le
			          permite prestar asesorías caracterizadas por la cercanía a
			          los problemas de los clientes, siendo la comprensión global
			          de los desafío y problemáticas de los clientes su objetivo
			          principal al momento de abordar una asesoría.
			        </p>
			      </div>
			    </div>
			    <div className="row align-items-start">
		        {isMobile ?
		          <div className="col-md-5">
		            <img src="/equipo/ricardo.png" width="100%" className="img-fluid" />
		          </div>
		          :
		          null
		        }
			      <div className="col-md-7">
			        <h4 className={(isMobile ? "text-center" : "") + " text-secondary service-heading pl-3"} >
			          Ricardo Jungk G.
			        </h4>
			        <p className="text-primary text-justify p-3">
			          Estudiante de 4 año Universidad de los Andes egresado del colegio 
			          alemán con dominio de 3 lenguas, español, alemán e inglés. 
			          Minor en ingles legal en la Universidad de los Andes y una muy 
			          buena disposición para ayudar y encontrar soluciones. 
			          Ha participado en múltiples creaciones de emprendimientos y 
			          pequeñas empresas y ayudado tanto en el marco de los negocios 
			          como en el marco legal de su constitución.
			        </p>
			      </div>
		        {!isMobile ?
		          <div className="col-md-5">
		            <img src="/equipo/ricardo.png" width="100%" className="img-fluid" />
		          </div>
		          :
		          null
		        }
			    </div>
			  </div>
			</section>
    );
  }
}
