import React, { Component } from 'react';

import { isMobile } from "react-device-detect";

export default class Personas extends Component {
  render() {
    return (
    	<section id="personas">
			  <div id="personas" className="container">
			    <div className="row">
			      <div className="col-lg-12 text-center">
			      <h1 className={(isMobile ? "display-4" : "display-1") + " section-heading text-white"}>
	            Servicios para personas
	          </h1>
			      </div>
			    </div>
			    <div className="row text-center">
			      <div className="col-md-4 flourish-col">
			        <div className="flourish flourish-terciary">
			          <span className="fa-stack fa-4x">
			            <img src="/personas/que.svg" />
			          </span>
			        </div>
			        <p className="text-white text-justify mt-5 pt-3 pl-3 pr-3">
			          <b>Queremos apoyar a las personas</b>
			          con capacidades diferentes y con
			          problemas reales.
			        </p>
			        <p className="text-white text-justify pl-3 pr-3">
			          <b>Tramitamos interdicciones</b> y 
			          apoyamos a las personas que han
			          sido discriminadas o han sufrido
			          daños.
			        </p>
			      </div>
			      <div className="col-md-4 flourish-col">
			        <div className="flourish flourish-terciary">
			          <span className="fa-stack fa-4x">
			            <img src="/personas/quienes.svg" />
			          </span>
			        </div>
			        <p className="text-white text-justify mt-5 p-3">
			          <b>Somos un equipo que disfruta
			          con lo que hace</b>, dirigido por
			          una abogado con <b>vocación y
			          más de 10 años de experiencia
			          en el trabajo con personas y empresas</b>,
			          acompañada de una familia joven
			          dispuesta a aprender y atender
			          tus requerimientos.
			        </p>
			      </div>
			      <div className="col-md-4 flourish-col">
			        <div className="flourish flourish-terciary">
			          <span className="fa-stack fa-4x linea">
			            <img src="/personas/como.svg" />
			          </span>
			        </div>
			        <p className="text-white text-justify mt-5 p-3">
			          Nos caracterizamos por otorgar
			          un <b>acompañamiento legal
			          personalizado</b> para las gestión
			          de los trámites que enfrentas,
			          sabemos que no estamos en
			          tu situación, pero <b>intentamos
			          comprender la problemática</b>
			          que enfrentas de una menara
			          general, considerando los costos
			          económicos y emocionales de
			          caso para buscar <b>soluciones
			          a la medida de tu caso en
			          particular.</b>
			        </p>
			      </div>
			    </div>
			  </div>
			</section>
    );
  }
}
