import React, { Component } from "react";

import { isMobile } from "react-device-detect";

import AnchorLink from "react-anchor-link-smooth-scroll";

export default class Footer extends Component {
	render() {
		return (
			<footer>
				<div className="container">
					<div className="row justify-content-md-center mb-4">
						<div className="col-md-4">
							<AnchorLink href="#page-top">
								<img src="/footer/subir.svg" width="60px" />
							</AnchorLink>
						</div>
					</div>
					{/*
			    <div className="row justify-content-md-center">
			      <div className="col-md-12">
			        <ul className="list-inline social-buttons">
			          <li className="list-inline-item">
			            <img src="/footer/facebook.svg" style={{width: "30px"}} />
			          </li>
			          <li className="list-inline-item">
			            <img src="/footer/instagram.svg" style={{width: "30px"}} />
			          </li>
			          <li className="list-inline-item">
			            <img src="/footer/pinterest.svg" style={{width: "30px"}} />
			          </li>
			          <li className="list-inline-item">
			            <img src="/footer/snapchat.svg" style={{width: "30px"}} />
			          </li>
			          <li className="list-inline-item">
			            <img src="/footer/twitter.svg" style={{width: "30px"}} />
			          </li>
			          <li className="list-inline-item">
			            <img src="/footer/linkedin.svg" style={{width: "30px"}} />
			          </li>
			        </ul>
			      </div>
			    </div>
			    */}
					<div className="row justify-content-md-center">
						<div className="col-md-4">
							<span className="copyright">
								Copyright &copy; Innovolegal 2018
							</span>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}
