import React, { Component } from 'react';

import { isMobile } from "react-device-detect";

import AnchorLink from 'react-anchor-link-smooth-scroll'

export default class Header extends Component {
  render() {
    return (
			<header className="masthead" id="page-top">
			  <div className="container">
			    <div className="intro-text">
			      <div className="intro-lead-in pb-5" style={{fontSize: "30px"}}>
			        Te ofrecemos <b>acompañamiento legal</b> para tu empresa o caso.
			      </div>
          	<AnchorLink offset="100" id="boton" className="btn btn-secondary btn-lg js-scroll-trigger" href="#contact">
          		Contáctanos
          	</AnchorLink>
			    </div>
			  </div>
			</header>
    );
  }
}
