import React, { Component } from 'react';
import logo from './logo.svg';
import './App.scss';

import NavBar from "./components/navbar.js"
import Header from "./components/header.js"
import About from "./components/about.js"
import Divisor from "./components/divisor.js"
import Team from "./components/team.js"
import Empresas from "./components/empresas.js"
import Personas from "./components/personas.js"
import Contacto from "./components/contacto.js"
import Clientes from "./components/clientes.js"
import Footer from "./components/footer.js"

import { isMobile } from "react-device-detect";

class App extends Component {
  render() {
    return (
      <div className="App">
        <NavBar />
        <Header />
        <About />
        <Divisor />
        <Team />
        <Empresas />
        <Divisor />
        <Personas />
        <Divisor />
        <Contacto />
        <Clientes />
        <Footer />
      </div>
    );
  }
}

export default App;
