import React, { Component } from 'react';

import { isMobile } from "react-device-detect";

export default class  extends Component {
  render() {
    return (
			<section id="about">
			  <div id="nosotros" className="container">
			    <div className="row">
			      <div className="col-lg-12 text-center">
			        <h1 className={(isMobile ? "display-4" : "display-1") + " section-heading linea-arriba"}>
			            Nosotros
			        </h1>
			      </div>
			    </div>
			    <div className="row text-center">
			      <div className="col-md-4 flourish-col">
			        <div className="flourish flourish-secondary">
			          <span className="fa-stack fa-4x">
			            <img src="/nosotros/quienes_somos.svg" />
			          </span>
			        </div>
			        <h4 className="text-secondary service-heading">Quiénes somos</h4>
			        <p className="text-primary text-justify pt-3 pl-3 pr-3">
			          <b>Somos un estudio familiar</b> que
			          pone a tu disposición más de
			          10 años de experiencia en la
			          asesoría y acompañamento legal
			          de emprendedores y de quienes
			          quieren emprender.
			        </p>
			        <p className="text-primary text-justify pl-3 pr-3">
			          Somos una <b>alternativa económica</b>,
			          ya que hemos reducido nuestros
			          costo en beneficio de nuestros
			          clientes, adaptándonos a los
			          tiempos de hoy. 
			        </p>
			      </div>
			      <div className="col-md-4 flourish-col">
			        <div className="flourish flourish-secondary">
			          <span className="fa-stack fa-4x">
			            <img src="/nosotros/que_ofrecemos.svg" />
			          </span>
			        </div>
			        <h4 className="text-secondary service-heading">Qué ofrecemos</h4>
			        <p className="text-primary text-justify p-3">
			          Ponemos énfasis en trabajo
			          jurídico de calidad en el área
			          civil, comercial, corporativa y
			          laboral, ayudando a nuestros
			          clientes a prevenir y contener
			          contingencias tales como riesgos
			          e incumplimientos contractuales,
			          multas, juicios, etc.
			        </p>
			      </div>
			      <div className="col-md-4 flourish-col">
			        <div className="flourish flourish-secondary">
			          <span className="fa-stack fa-4x linea">
			            <img src="nosotros/nuestra_experiencia.svg" />
			          </span>
			        </div>
			        <h4 className="text-secondary service-heading">Nuestra experiencia</h4>
			        <p className="text-primary text-justify p-3">
			          Buscamos que nuestra experiencia
			          de más de 10 años se ponga al
			          servicio de tus negocios para
			          que sean ágiles, dinámicos y
			          seguros al poner a tu disposición
			          un back up de los trabajos
			          realizados para ti, como también
			          capacitaciones a tu medida en
			          diversas materias asociadas a
			          tu área de negocios tales como
			          aspectos claves de los contratos
			          de trabajo, de prestaciones de
			          servicios, propiedad intelectual,
			          transferencia de tecnología,
			          fomentos a la innovación en Chile,
			          entre otras.
			        </p>
			      </div>
			    </div>
			  </div>
			</section>
    );
  }
}
