import React, { Component } from "react";

import { isMobile } from "react-device-detect";

export default class Clientes extends Component {
	render() {
		return (
			<section className="py-5">
				<div className="container">
					<div className="row justify-content-md-center">
						<div className="col-md-3 col-sm-6 content">
							<img
								alt="thecne"
								src="/footer/clientes/thecne.png"
								width="100%"
								className="cliente"
								id="cliente"
							/>
						</div>
						<div className="col-md-3 col-sm-6 content">
							<img
								alt="indurad"
								src="/footer/clientes/indurad.png"
								width="100%"
								className="cliente"
								id="cliente"
							/>
						</div>
						<div className="col-md-3 col-sm-6">
							<img
								alt="robinruth"
								src="/footer/clientes/robinruth.png"
								width="100%"
								className="cliente"
								id="cliente"
							/>
						</div>
						<div className="col-md-3 col-sm-6 content">
							<img
								alt="sinhuella"
								src="/footer/clientes/sinhuella.png"
								width="100%"
								className="cliente"
								id="cliente"
							/>
						</div>
					</div>
				</div>
			</section>
		);
	}
}
