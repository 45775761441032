import $ from "jquery";
var jQuery = $;

(function($) {
  "use strict"; // Start of use strict

  // Activate scrollspy to add active class to navbar items on scroll
  $('body').scrollspy({
    target: '#mainNav',
    offset: 110
  });

})(jQuery); // End of use strict
