import React, { Component } from 'react';

import { isMobile } from "react-device-detect";

import AnchorLink from 'react-anchor-link-smooth-scroll'

import { Collapse, NavbarToggler } from 'reactstrap';

export default class NavBar extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    document.addEventListener('click', this.handleDocumentClick, true);
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  handleDocumentClick(e) {
    const container = this._element;
    if (this.state.isOpen) {
      this.toggle();
    }
  }
  render() {
    return (
      <div ref={(c)=> (this._element = c)}>
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
          <div className="container">
            <AnchorLink className="navbar-brand js-scroll-trigger" href="#page-top">
              <img src="/logos/logo.svg" width={isMobile ? "200px" : "300px"} />
            </AnchorLink>
            <NavbarToggler onClick={this.toggle}>Menu<i className="fa fa-bars ml-1"></i></NavbarToggler>
            <Collapse isOpen={this.state.isOpen} navbar>
              <ul className="navbar-nav text-uppercase ml-auto">
                <li className="nav-item">
                  <AnchorLink offset="100" className="nav-link js-scroll-trigger" href="#about">Nosotros</AnchorLink>
                </li>
                <li className="nav-item">
                  <AnchorLink offset="100" className="nav-link js-scroll-trigger" href="#team">Equipo</AnchorLink>
                </li>
                <li className="nav-item">
                  <AnchorLink offset="100" className="nav-link js-scroll-trigger" href="#empresas">Empresas</AnchorLink>
                </li>
                <li className="nav-item">
                  <AnchorLink offset="100" className="nav-link js-scroll-trigger" href="#personas">Personas</AnchorLink>
                </li>
                <li className="nav-item">
                  <AnchorLink offset="100" className="nav-link js-scroll-trigger" href="#contact">Contacto</AnchorLink>
                </li>
              </ul>
            </Collapse>
          </div>
        </nav>
      </div>
    );
  }
}
