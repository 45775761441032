import React, { Component } from 'react';

import { isMobile } from "react-device-detect";

export default class Empresas  extends Component {
  render() {
    return (
    	<section id="empresas">
			  <div id="empresas" className="container">
			    <div className="row">
			      <div className="col-lg-12 text-center">
			      <h1 className={(isMobile ? "display-4" : "display-1") + " section-heading text-white"}>
	            Servicios para empresas
	          </h1>
			      </div>
			    </div>
			    <div className="row text-center">
			      <div className="col-md-4 flourish-col">
			        <div className="flourish flourish-white">
			          <span className="fa-stack fa-4x">
			            <img src="/empresas/que.svg" />
			          </span>
			        </div>
			        <p className="text-white text-justify mt-5 p-3">
			          <b>Asesoría y acompañamiento
			          legal a la medida de tu
			          emprendimiento</b>, diagnósticamos
			          tus requerimientos y nos hacemos
			          cargo de ellos por un valor fijo
			          mensual razonable.
			        </p>
			      </div>
			      <div className="col-md-4 flourish-col">
			        <div className="flourish flourish-white">
			          <span className="fa-stack fa-4x">
			            <img src="/empresas/quienes.svg" />
			          </span>
			        </div>
			        <p className="text-white text-justify mt-5 p-3">
			          <b>Somos un equipo que disfruta
			          con lo que hace</b>, dirigido por una
			          abogado con vocación y más de 10
			          años de experiencia en el trabajo
			          con empresas, acompañada de una
			          familia joven dispuesta a aprender
			          y atender tus requerimientos.
			        </p>
			      </div>
			      <div className="col-md-4 flourish-col">
			        <div className="flourish flourish-white">
			          <span className="fa-stack fa-4x linea">
			            <img src="/empresas/como.svg" />
			          </span>
			        </div>
			        <p className="text-white text-justify mt-5 p-3">
			          Nos caracterizamos por otorgar un
			          acompañamiento legal y consciente
			          de los desafíos de tu negocio,
			          <b>te apoyamos y contenemos
			          haciéndonos parte de tu equipo</b>,
			          buscamos aliviarte de los problemas
			          legales, para que sientas que la
			          toma de decisiones es informada
			          y que el manejo de tu negocio es
			          dinámico y ágil.
			        </p>
			      </div>
			    </div>
			  </div>
			</section>
    );
  }
}
