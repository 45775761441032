import React, { Component } from 'react';

import { isMobile } from "react-device-detect";

export default class Contacto extends Component {
  constructor () {
  	super();
    this.state = {
    	personas: true,
    	nombre: "",
    	mail: "",
    	telefono: "",
    	mensaje: "",
    	exito: ""
    };
  }
  handleChange(i, e) {
  	if (i == 0) {
  		this.setState({personas: e})
  	}
  	else if (i == 1) {
  		this.setState({nombre: e.target.value})
  	}
  	else if (i == 2) {
  		this.setState({mail: e.target.value})
  	}
  	else if (i == 3) {
  		this.setState({telefono: e.target.value})
  	}
  	else if (i == 4) {
  		this.setState({mensaje: e.target.value})
  	}
  }
  handleSend() {
		var body = {
			nombre: this.state.nombre,
			mail: this.state.mail,
			telefono: this.state.telefono,
			mensaje: this.state.mensaje,
			persona: this.state.personas ? "1" : "0"
	  }
	  this.setState({exito: "Enviando..."})
		fetch('https://us-central1-innovolegal.cloudfunctions.net/sendForm', {
		  method: 'POST',
		  headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
  		}),
  		mode: "no-cors",
		  body: serialize(body)
		})
		.then(response => {
			this.setState({exito: "Tu mensaje ha sido recibido. Te contactaremos a la brevedad."})
			setTimeout(function(){
	      this.setState({exito: ""})
	    }.bind(this),5000);
	  })
	  .catch((error) => {  
		  console.log('Request failed', error)  
		  this.setState({exito: "Hubo un problema. Por favor intenta denuevo."})
		});
	}
  render() {
		var ph_nombre = "Nombre *"
		var ph_mail = "Mail *"
		var ph_telefono = "Telefono *"
  	if (!this.state.personas) {
  		var ph_nombre = "Nombre Empresa *"
  		var ph_mail = "Mail de Contacto*"
  		var ph_telefono = "Telefono de Contacto*"
  	}
    return (
    	<section id="contact">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12 text-center">
			      	<h1 className={(isMobile ? "display-4" : "display-1") + " section-heading text-primary linea-arriba"}>
			          Contáctanos
			        </h1>
			      </div>
			    </div>
			    <div className="row">
			      <div className="col-lg-12">
			        <form id="contactForm" name="sentMessage">
			          <div className="row justify-content-md-center">
			            <div className="col-md-8">
			              <div className="btn-group form-group mb-4" style={{width: "100%"}}>
			                <button className={(this.state.personas ? "btn-primary" : "btn-secondary") + " btn-lg btn"} id="tipo1" onClick={(e) => {e.preventDefault(); this.handleChange(0, true);}}> Personas </button>
			                <span className="btn-separator"></span>
			                <button className={(!this.state.personas ? "btn-primary" : "btn-secondary") + " btn-lg btn"} id="tipo2" onClick={(e) => {e.preventDefault(); this.handleChange(0, false);}}> Empresas </button>
			              </div>
			            </div>
			            <div className="col-md-12">
			              <div className="form-group">
			                <input className="form-control" id="name" type="text" placeholder={ph_nombre} value={this.state.nombre} onChange={(e) => this.handleChange(1, e)} />
			              </div>
			              <div className="form-group">
			                <input className="form-control" id="email" type="email" placeholder={ph_mail} value={this.state.mail} onChange={(e) => this.handleChange(2, e)} />
			              </div>
			              <div className="form-group">
			                <input className="form-control" id="phone" type="tel" placeholder={ph_telefono} value={this.state.telefono} onChange={(e) => this.handleChange(3, e)}/>
			              </div>
			              <div className="form-group">
			                <textarea className="form-control" id="message" placeholder="Mensaje *" value={this.state.mensaje} onChange={(e) => this.handleChange(4, e)}></textarea>
			              </div>
			            </div>
			            <div className="clearfix"></div>
			            <div className="col-lg-12 text-center">
			              <div id="success">{this.state.exito}</div>
			              <button id="sendMessageButton" className="btn btn-primary btn-lg" onClick={(e) => {e.preventDefault(); this.handleSend();}}>Enviar Mensaje</button>
			            </div>
			          </div>
			        </form>
			      </div>
			    </div>
			  </div>
			</section>
    );
  }
}

function serialize(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}