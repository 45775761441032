import React, { Component } from 'react';

import { isMobile } from "react-device-detect";

export default class  extends Component {
  render() {
    return (
    	<div className="container text-center p-5">
			  <img src="/logos/triangulo.svg" width="100" />
			</div>
    );
  } 
}
